@font-face {
  font-family: 'NotoSansLight';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/notosans-light/notosans-light-webfont.ttf'),
    url('../public/assets/fonts/notosans-light/notosans-light-webfont.woff'),
    url('../public/assets/fonts/notosans-light/notosans-light-webfont.woff2');
}

@font-face {
  font-family: 'NotoSansRegular';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/notosans-regular/notosans-regular-webfont.ttf'),
    url('../public/assets/fonts/notosans-regular/notosans-regular-webfont.woff'),
    url('../public/assets/fonts/notosans-regular/notosans-regular-webfont.woff2');
}

@font-face {
  font-family: 'NotoSansMedium';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/notosans-medium/notosans-medium-webfont.ttf'),
    url('../public/assets/fonts/notosans-medium/notosans-medium-webfont.woff'),
    url('../public/assets/fonts/notosans-medium/notosans-medium-webfont.woff2');
}

@font-face {
  font-family: 'NotoSansSemiBold';
  font-style: normal;
  font-weight: 700;
  src: url('../public/assets/fonts/notosans-semibold/notosans-semibold-webfont.ttf'),
    url('../public/assets/fonts/notosans-semibold/notosans-semibold-webfont.woff'),
    url('../public/assets/fonts/notosans-semibold/notosans-semibold-webfont.woff2');
}

body {
  margin: 0;
  font-family: NotoSansRegular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

input {
  font-size: 16px !important;
}

.grecaptcha-badge {
  visibility: hidden;
  right: 15px !important;
}
